//Get animator divs
var animatorQueue = document.getElementsByClassName("animatorTarget");

//Add hover listeners
for(divElement of animatorQueue){
	divElement.addEventListener("mouseenter", onElementHover, false);
	divElement.addEventListener("mouseleave", onElementUnhover, false);
}

function onElementHover(event){
	event.target.setAttribute('data-playForward', true);
}

function onElementUnhover(event){
	event.target.removeAttribute('data-playForward');
}

var intervalId = window.setInterval(animate, 100)
function animate(){
	for(divElement of animatorQueue){
		playForward = divElement.getAttribute('data-playForward');
		framesCount = divElement.getAttribute('data-framesCount');
		currentFrameNumber = parseInt(divElement.getAttribute('data-currentOffset'));
		
		if(!playForward && currentFrameNumber <= 0)
			continue;
		
		if(playForward && currentFrameNumber >= framesCount)
			continue;
		
		if(playForward)
			newFrameNumber = currentFrameNumber + 1;
		else{
			newFrameNumber = currentFrameNumber - 1;
		}
		
		divElement.setAttribute('data-currentOffset', newFrameNumber);
		width = divElement.offsetWidth;
		divElement.style.backgroundPosition = -(width * newFrameNumber) + "px 100%";
	}
}
